
.popupFavoriteContainer {
  min-width: 300px;
}

.popupFavoriteTitle {
  font-size: 1rem;
  color: lightgrey;
  margin-bottom: 15px;
}

.popupFavoriteDescription {
  margin-bottom: 5px;
}

.popupFavoriteInput {
  width: 100%;
  height: 25px;
  border: unset;
  border-radius: 5px;
}


.popupSettingsContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.popupSettingsButtonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.popupSettingsTextArea {
  width: 100%;
  min-height: 500px;
  color: white;
  padding: 10px;
  border: unset;
  border-radius: 10px;
  background-color: #FFFFFF22;
  resize: none;
}

.popupAdvancedSettingsContainer {
  width: 100%;
  color: white;
  padding: 30px;
  border: unset;
  border-radius: 10px;
  background-color: #FFFFFF22;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.popupDevicesContent {
  min-width: 700px;
  min-height: 500px;
  color: white;
  padding: 10px;
  border: unset;
  border-radius: 10px;
  background-color: #FFFFFF22;
}

.button {
  flex: 1;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}