
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-main-white);
  color: white;
  background-size: cover;
  background-position: center;
}

.header {
  position: absolute;
  right: 0px;
  top: 0px;
}

.loading {
  z-index: 10000;
}

.whiteboardContainer {
  width: 100%;
  flex: 1;
  min-height: 0;
}

.hideElementTransition {
  opacity: 0;
  transition: opacity 3s 0s;
}

.displayElementTransition {
  opacity: 1;
  transition: opacity 1s 0s;
}