.buttonContainer {
  position: relative;
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(var(--color-mirage-rgb), 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWithAdditionnalContent {
  width: unset;
  gap: 5px;
}

.additionnalDataDisplayWhiteboardContainer {
  width: unset;
}

.additionnalDataDisplayWhiteboardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  color: lightgrey;
}

.additionnalDataDisplayWhiteboardCheckBox {
  --checkbox-content-size: 15px;
  --checkbox-size: 15px;
  opacity: 0.7;
}

.loadingButton {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(var(--color-mirage-rgb), 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

