:root {
  /* KAST-APP-WEB-COMPONENTS */
  --badge-border-radius: 15px;
  --badge-border-radius-empty: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  background-color: white;
}

.backgroundComponent {
  background-color: #00000055;
  backdrop-filter: blur(8px);
}


/* --------------------------------------- 
--------------------------------------- 
              SCROLLBAR
--------------------------------------- 
--------------------------------------- 
*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-background-color);
}


/* --------------------------------------- 
--------------------------------------- 
              TOAST
--------------------------------------- 
--------------------------------------- 
*/
.toast {
  box-shadow: 0px 2px 10px #00000088;
  --toastify-text-color-light: var(--color-main-black);
  --toastify-color-light: rgba(var(--color-grey-athens-rgb), 1);
  --toastify-font-family: var(--font-signika);
}
/*
.toast {
  --toastify-color-light: var(--color-light-mirage);
  --toastify-icon-color-info: var(--color-galerry);
  --toastify-font-family: var(--font-signika);
  --toastify-text-color-light: var(--color-main-white);
  --toastify-color-progress-info: linear-gradient(to right,var(--color-grey-santas),var(--color-galerry));
}*/