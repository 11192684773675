.socketInfoContainer {
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 5px #000000AA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: var(--font-halisR);
  gap: 20px;
}

.socketInfoContainerLoading {
  background-color: var(--color-tree-poppy);
}

.socketInfoContainerOffline {
  background-color: var(--color-torch-red);
}

.socketRetryButton {
  font-size: 12px;
  background-color: white;
  border-radius: 3px;
  padding: 2px 15px;
  color: black;
  box-shadow: 0px 0px 5px #000000AA;
}
