/* https://www.w3schools.com/howto/howto_css_blurred_background.asp */

.container {
  background-color: var(--color-purpule-heart);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  background-size: cover;
  background-position: center;
}

.backButton {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 3;
  width: 40px;
  padding: 10px;
  -webkit-filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
}

.contentcontainer {
  width: 400px;
  padding: 20px;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--color-blue-bayoux-rgb), 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  color: white;
}
.icon {
  height: 80px;
  width: 80px;
  padding: 10px;
  fill: white;
  stroke: white;
}
