
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-purpule-heart);
  color: white;
  background-size: cover;
  background-position: center;
}

.loading {
  z-index: 10000;
}

.mainContent {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.whiteboardContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}


.hideContent {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.contentContainer {
  flex: 1;
  width: 100%;
  min-height: 0;
}

.contentMessage {
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sessionContainer {
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.waiting {
  width: 100%;
  text-align: center;
}

.nobody {
  width: 100%;
  text-align: center;
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.footer {
  border-radius: 10px 10px 0px 0px;
  background-color: #00000044; 
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 0px 10px 15px;
}

.whiteboardFooterContainer {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.whiteboardFooter {
  border-radius: 10px 10px 10px 10px;
  background-color: #00000044; 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}

.footerButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: 120px;
  height: 50px;
  font-size: 1rem;
  color: white;
}

.footerButtonSelected {
  color: var(--color-main-purpule);
}
