.container {
  border: unset;
  position: relative;
}

.videoAudioButtonContainer {
  width: unset;
  height: unset;
  padding: unset;
}

.audioButton {
  --media-capture-enable-audio-button-padding: 3px;
  width: 30px;
  height: 30px;
}

.videoButton {
  --media-capture-enable-video-button-padding: 3px;
  width: 30px;
  height: 30px;
}
