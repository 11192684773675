.video_controls-row-buttons-container {
  width: 15px;
}

.camera_controls-button-move {
  width: 35px !important;
  height: 35px !important;
}


.icon_selector-button-vertical img {
  width: 35px !important;
  height: 35px !important;
}

.player_controls-buttons-support {
  display: none;
}

.player-controls-container {
  z-index: 1;
}

.player-container {
  background-color: transparent;
}