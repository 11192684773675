.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-purpule-heart);
  color: white;
  background-size: cover;
  background-position: center;
}

.container {
  width: 600px;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(var(--color-blue-bayoux-rgb), 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
}

.content {
  flex: 1;
  min-height: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-size: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-bottom: 30px;
}

.description {
  font-size: 1rem;
  color: lightgrey;
  text-align: start;
  margin-bottom: 10px;
}

.input {
  border: unset;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-size: 1.2rem;
  padding: 0px 5px;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button {
  width: 100%;
  height: 40px;
  border-radius: 0px;
}



.disabled {
  background-color: grey;
}

.backButton {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 3;
  width: 40px;
  padding: 10px;
  -webkit-filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
  filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
}