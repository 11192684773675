:root {
  --border-radius: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(var(--color-blue-bayoux-rgb), 0.4);
  padding: 3px;
  width: 300px;
  gap: 2px;
}

.using {
  background-color: rgba(var(--color-main-purpule-rgb), 0.7);
}

.header {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 0;
}

.footer {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(var(--color-main-purpule-rgb), 1);
  border-radius: 10px;
}

.player {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  overflow: hidden;
}

.borderTopOnly {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.borderBottomOnly {
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.playerKast {
  width: 100%;
  height: 170px;
}

.playerOvenMedia {
  width: 100%;
  height: 170px;
}

.playerWhiteboard {
  width: 100%;
  height: 170px;
}

.playerWeb {
  width: 100%;
  height: 170px;
}

.hide {
  display: none;
}

.videoStats {
  position: absolute;
  top: 0;
  left: 0;
}

.error {
  position: absolute;
  top: 5px;
  right: 5px;
}

.errorHover {
  background-color: #9f930b;
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 10px;
}