
.detailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--color-pickled-bluewood);
  box-shadow: 0px 0px 5px #00000044;
  column-rule: solid 1px black;
  gap: 10px;
  color: white;
}

.detailsColumnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.detailsColumnTitle {
  font-size: 16px;
  margin-bottom: 5px;
}


.detailsStats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  width: 100%;
}

.detailsStatsSeparator {
  width: 80%;
  height: 1px;
  background-color: #00000044;
  margin: 3px auto;
}

.detailsTrackStatsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #FFFFFF22;
  width: 100%;
  padding-bottom: 5px;
}

.detailsTrackStatsTitle {
  font-size: 12px;
  overflow: hidden;
  background-color: #00000022;
  color: lightgrey;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.detailsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 5px;
}

.detailsTitle {
  color: white;
  font-size: 12px;
  min-width: 110px;
}

.detailsValue {
  color: white;
  font-size: 10px;
}