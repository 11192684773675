
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-purpule-heart);
  color: white;
  background-size: cover;
  background-position: center;
}

.loading {
  z-index: 10000;
}

.session {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.mysession {
  /*background-color: #00FF0044;*/
  background-color: rgba(var(--color-main-purpule-rgb), 0.5);
}

.sessionContainer {
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sessionHeader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}


.viewerContainer {
  color: var(--color-main-green);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  padding: 2px 8px;
}

.contentContainer {
  flex: 1;
  width: 100%;
  min-height: 0;
  padding: 20px;
  overflow: auto;
}

.contentMessage {
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.waiting {
  width: 100%;
  text-align: center;
}

.nobody {
  width: 100%;
  text-align: center;
}

.sourcesContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 10px 10px;
  max-width: 80%;
  padding: 10px 20px;
  background-color: #00000022;
  box-shadow: inset 0px 2px 5px #00000020;
}
.sourcesContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.sourceButton {
  padding: 5px 10px;
  min-width: 80px;
  border-radius: 10px;
  background-color: rgba(var(--color-grey-dusty-rgb), 0.4);
  text-align: center;
}

.sourcesTitle {
  text-align: center;
  margin-bottom: 10px;
}

.sourceButtonSelected {
  background-color: rgba(var(--color-main-purpule-rgb), 1);
}

/*
@media only screen and (min-width: 850px)  {
  .sourcesContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    padding: 20px 10px;
    border-radius: 0px 10px 10px 0px;
    background-color: #00000044;
    max-width: unset;
  }

  .sourcesContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 3px;
  }
  
  .sourceButton {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    background-color: rgba(var(--color-grey-dusty-rgb), 0.4);
    text-align: center;
  }
}
*/