:root {
  --meeting-sources-radius:  20px;
  --meeting-sources-selector-width: 40px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 95%;
}

.header {
  padding: 5px 10px;
  font-size: 1.25rem;
  /*font-weight: bold;*/
  /*font-family: var(--font-signika);*/
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 260px;
  min-height: 200px;
  border-radius: 0px var(--meeting-sources-radius) var(--meeting-sources-radius) 0px;
  height: 100%;
  padding: 5px 0px;
  margin-right: var(--meeting-sources-selector-width);
}

.closed {
  min-width: 0px;
  min-height: 0px;
  width: 0px;
  height: 0px;
  visibility: hidden;
}

.sourcesContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0px 15px;
  min-height: 0;
  overflow: auto;
}

.selector {
  position: absolute;
  z-index: 1;
  height: 80px;
  width: var(--meeting-sources-selector-width);
  top: 50%;
  right: var(--meeting-sources-selector-width);
  transform: translate(100%, -50%);
  overflow: hidden;
  background-color: var(--meeting-sources-background-color);
  border-radius: 0px var(--meeting-sources-radius) var(--meeting-sources-radius) 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.iconIsOpened {
  width: 15px;
  transform: rotate(-90deg);
}

.iconContainer {
  position: relative;
}

.iconIsClosed {
  width: 15px;
  transform: rotate(90deg);
  opacity: 0;
  transition: opacity 1s;
}

.iconCamera {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  opacity: 1;
  transition: opacity 2s;
}

.iconContainer:hover .iconIsClosed {
  opacity: 1;
  transition: opacity 0.3s;
}

.iconContainer:hover .iconCamera {
  opacity: 0;
  transition: opacity 0.3s;
}
