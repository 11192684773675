.container {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  /*display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;*/
  /* [Start] Remplace flex by grid for whiteboard: Newline */
  display: grid;
  grid-auto-flow: column;
  /* [End] */
  gap: 5px;
  border-radius: 10px 10px 0px 0px;
  max-width: 80%;
  padding: 7px 6px 3px 6px;
  background-color: rgba(var(--color-blue-prussian-rgb), 0.95);
  transition-property: transform;
  transition-duration: 0.5s;
}

.reduce {
  transform: translate(-50%, 100%);
  transition-property: transform;
  transition-duration: 0.5s;
}


.displayButtonContainer {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-blue-prussian-rgb), 0.95);
  border-radius: 10px 10px 0px 0px;
  padding: 3px 15px;
}

.displayButton {
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  transition-property: transform;
  transition-duration: 0.5s;
}

.reduce .displayButton {
  transform: rotate(0deg);
  transition-property: transform;
  transition-duration: 0.5s;
}

.sessionContainer {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /* [Start] Remplace flex by grid for whiteboard: Newline */
  display: grid;
  /* [End] */
  gap: 3px;
  border-radius: 10px;
  background-color: #FFFFFF22;
  padding: 2px 6px;
  min-width: 120px;
}

.mysession {
  /*background-color: #00FF0044;*/
  background-color: rgba(var(--color-main-purpule-rgb), 0.5);
}

.sessionName {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sessionContent {
  height: 30px;
  /*display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;*/
  /* [Start] Remplace flex by grid for whiteboard: Newline */
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  justify-content: center;
  /* [End] */
  gap: 5px;
}

.videoAudioButtonContainer {
  width: unset !important;
  height: unset !important;
  padding: unset !important;
}

.audioButton {
  --media-capture-enable-audio-button-padding: 3px;
  width: 30px;
  height: 30px;
}

.videoButton {
  --media-capture-enable-video-button-padding: 3px;
  width: 30px;
  height: 30px;
}
