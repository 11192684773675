.container {
  width: 200px;
  padding: 10px;
  background-color: #00000066;
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.title {

}

.value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}