
.mainContainer {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mobileBarButton {
  height: 100%;
  padding: 0px 20px 0px 10px;
}

.main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 1px;
}

.message {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1px;
  width: 100%;
  color: white;
  white-space: pre-wrap;
  text-align: center;
}

.errorDescription {
}

.errorRetry {
  margin-top: 10px;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.retryButton {
  margin-top: 15px;
}

.rightPane {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.rightPaneContent {
  flex: 1;
  width: 100%;
  background-color: rgb(210, 210, 210);
  /*overflow: auto;*/
  min-height: 0px;
}