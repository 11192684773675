/* https://www.w3schools.com/howto/howto_css_blurred_background.asp */

.container {
  background-color: var(--color-purpule-heart);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  background-size: cover;
  background-position: center;
}

.logoContainer {
  width: 100%;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoKalyzeeContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.by {
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: white;
}

.logoKalyzee {
  width: 200px;
  max-width: 25%;
}

.loginContainer {
  width: 400px;
  padding: 20px;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--color-blue-bayoux-rgb), 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  gap: 10px;
}

.title {
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 30px;
}

.input {
  width: 100%;
  border: rgb(227, 226, 226) 1px solid;
  height: 30px;
  border-radius: 3px;
  margin-top: 10px;
  padding-left: 10px;
}

.button {
  cursor: pointer;
  width: 100%;
  background-color: var(--color-main-green);
  border-radius: 3px;
  height: 35px;
  border: unset;
  box-shadow: 0px 0px 5px #000000aa;
  color: white;
  font-family: var(--font-halisR);
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  margin-top: 20px;
  width: 100%;
  background-color: var(--color-grey-santas);
  border-radius: 3px;
  height: 35px;
  border: unset;
  box-shadow: 0px 0px 5px #000000aa;
  color: white;
}

.fieldError {
  text-align: left;
  color: red;
  font-size: 10px;
  width: 100%;
}

.textRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  gap: 5px;
}

.smallTextRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.7rem;
  gap: 5px;
}

.oauth2CodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}