.container {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 30px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  gap: 5px;
}

.leftContainer, .rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  flex: 1 1 0px;
}

.rightContainer {
  justify-content: flex-end;
}
.leftContainer {
  justify-content: flex-start;
}

.middleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
}


.smallTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF66;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 10px;
}

.largeTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF66;
  font-size: 30px;
  border-radius: 5px;
  padding: 0px 10px;
  text-shadow: 0px 0px 5px #00000077;
  color: white;
}

.largeTimeSeconds {
  font-size: 23px;
  border-radius: 5px;
  color: white;
  opacity: 0.9;
  text-shadow: 0px 0px 2px #00000077
}
