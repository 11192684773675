:root {
 --padding: 10px;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  top: var(--padding);
  left: var(--padding);
  width: calc(100% - 2 * var(--padding));
  height: calc(100% - 2 * var(--padding));
  gap: 10px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rowTitle {
  border-radius: 10px 10px 0px 0px;
  background-color: #AAAAAA66;
  padding: 3px 5px;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.rowContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 0px 0px 10px 10px;
  background-color: #DDDDDD66;
  padding: 5px;
  min-width: 100px;
}

.indexIndicator {
  position: absolute;
  top: -4px;
  right: -2px;
  font-size: 8px;
}