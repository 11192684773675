
.support {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(var(--color-blue-prussian-rgb), 0.2);
}

.container {
  width: 600px;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(var(--color-blue-prussian-rgb), 0.8);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
}

.content {
  flex: 1;
  min-height: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.description {
  font-size: 1rem;
  color: lightgrey;
  text-align: start;
  margin-bottom: 10px;
}

.input {
  border: unset;
  border-radius: 10px;
  height: 30px;
  width: 100%;
  font-size: 1.2rem;
  padding: 0px 5px;
}

.button {
  width: 100%;
  height: 40px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mountain-meadow);
  font-size: 1.2rem;
}

.disabled {
  background-color: grey;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.inputColumn {
  flex: 1;
}


.favoriteDescription {
  font-size: 1rem;
  color: lightgrey;
  text-align: start;
  margin-bottom: 10px;
}

.favoritesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.favoriteContainer {
  position: relative;
}

.favoriteButton {
  padding: 6px 10px;
  background-color: var(--color-main-purpule);
  box-shadow: 0px 0px 5px #00000033;
  border-radius: 5px;
}

.favoriteButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.favoriteRemoveButton {
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--color-main-purpule-rgb), 0.7);
  box-shadow: 0px 0px 5px #00000033;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  border: 1px solid #00000066;
}

.favoriteId {
  flex: 3;
  text-align: center;
}
.favoriteName {
  flex: 1;
  text-align: center;
  color: lightgrey;
  font-size: 11px;
}

.orContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}

.orSeparator {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid rgba(var(--color-blue-bayoux-rgb), 1);
}

.or {
  font-size: 18;
  font-weight: bold;
}

.error {
  color: var(--color-torch-red);
  font-size: 0.9rem;
  margin-top: 5px;
}