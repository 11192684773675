.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.content {
  position: relative;
  flex: 1;
  min-height: 0;
  width: 100%;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #000000AA;
}

.info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.message {
  text-shadow: 1px 1px 5px black;
  white-space: pre-wrap;
}

.avatar {
  width: unset;
  height: 100px;
  max-height: 100%;
  min-height: 0px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0px;
}


.header {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 2px;
  min-width: 0px;
  gap: 2px;
}

.headerCenter {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
}

.headerLeft,
.headerRight {
  min-width: 0;
}

.headerText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerHoverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--color-pickled-bluewood);
  box-shadow: 0px 0px 5px #00000044;
  column-rule: solid 1px black;
  gap: 5px;
  color: white;
}

.headerHoverRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 10px;
}

.headerHoverTitle {
  font-size: 12px;
  font-weight: bold;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-wrap: wrap;
  gap: 2px 5px;
}

.buttonContainer {
  position: relative;
}

.videoAudioButtonContainer {
  width: unset !important;
  height: unset !important;
  padding: unset !important;
}

.audioButton {
  --media-capture-enable-audio-button-padding: 3px;
  width: 30px;
  height: 30px;
}

.videoButton {
  --media-capture-enable-video-button-padding: 3px;
  width: 30px;
  height: 30px;
}

.reactionButton * {
  width: 100%;
  height: 100%;
}

.reactionButtonEnabled {
  background-color: rgba(var(--color-mountain-meadow-rgb), 0.6) !important;
}

.reactionsContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin: max(0px, 1%) max(0px, 2%);
  width: 400px;
  height: 200px;
  max-width: 75%;
  max-height: 75%;
  z-index: 0;
}

.reactionsContainer * {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  min-width: 0px;
  min-height: 0px;
}

.reaction {
  object-fit: contain;
  object-position: top right;
}

.reactionsContainer .animateReaction {
  width: 120px;
  height: 120px;
}

.reactionsContainer .animateRaisedHandReaction {
  animation: animateHand 5s linear infinite;
}
.reactionsContainer .animateClapReaction {
  animation: animateClap 5s linear infinite;
}
.reactionsContainer .animateThumbsdownReaction {
  animation: animateThumbsdown 3s linear infinite;
}
.reactionsContainer .animateThumbsupReaction {
  animation: animateThumbsup 3s linear infinite;
}
.reactionsContainer .animateOkReaction {
  animation: animateOk 3s linear infinite;
}
.reactionsContainer .animateTimeoutReaction {
  margin-left: 10px;
  margin-top: 10px;
  width: 100px;
  height: 100px;
  animation: animateTimeout 3s linear infinite;
}


@keyframes animateHand{
  0%{ transform: rotate(0deg); }
  2%{ transform: rotate(-20deg); }
  6%{ transform: rotate(20deg); }
  8%{ transform: rotate(0deg); }
  100%{ transform: rotate(0deg); }
}

@keyframes animateClap {
  0%{ transform: scale(1); }
  2%{ transform: scale(1.2); }
  4%{ transform: scale(1); }
  6%{ transform: scale(1.2); }
  8%{ transform: scale(1); }
  10%{ transform: scale(1.2); }
  12%{ transform: scale(1); }
  14%{ transform: scale(1.2); }
  16%{ transform: scale(1); }
  100%{ transform: scale(1); }
}

@keyframes animateThumbsdown {
  0%{ transform: translate(0px, 0px) rotate(0deg); }
  5%{ transform: translate(5px, 7px) rotate(10deg); }
  15%{ transform: translate(0px, 0px) rotate(0deg); }
  100%{ transform: translate(0px, 0px) rotate(0deg); }
}

@keyframes animateThumbsup {
  0%{ transform: translate(0px, 0px) rotate(0deg); }
  5%{ transform: translate(5px, -7px) rotate(-10deg); }
  15%{ transform: translate(0px, 0px) rotate(0deg); }
  100%{ transform: translate(0px, 0px) rotate(0deg); }
}

@keyframes animateOk {
  0%{ transform: scale(1) translate(0px, 0px) rotate(0deg); }
  10%{ transform: scale(1.2) translate(-5px, 0px) rotate(-5deg); }
  20%{ transform: scale(1) translate(0px, 0px) rotate(0deg); }
  100%{ transform: scale(1) translate(0px, 0px) rotate(0deg); }
}

@keyframes animateTimeout {
  0%{ transform: scale(1); }
  10%{ transform: scale(1.2); }
  20%{ transform: scale(1); }
  100%{ transform: scale(1); }
}

.videoStats {
  position: absolute;
  top: 0;
  left: 0;
}

.publishIndicator {
  font-size: 2rem;
  color: rgba(var(--color-torch-red-rgb), 0.8);
}

.playerNoStreamVolumControls {
  width: 60% !important;
  min-width: unset !important;
  border-radius: 10px;
  height: 23px;
  min-height: unset;
  background-color: rgba(153, 153, 153, 0.6) !important;
  margin-bottom: -10px;
  z-index: 1;
}