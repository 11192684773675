.secretButton {
  background-color: transparent;
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: var(--max-index);
}

.popupPasswordContainer {
  min-width: 300px;
}

.popupPasswordTitle {
  font-size: 1rem;
  color: lightgrey;
  margin-bottom: 15px;
}

.popupPasswordDescription {
  margin-bottom: 5px;
}

.popupPasswordInput {
  width: 100%;
  height: 25px;
  border: unset;
  border-radius: 5px;
}
