.itemContainer {
  width: 100%;
  height: 100%;
}

/************************************/
/*************** GRID ***************/
/************************************/

.grid {}

/************************************/
/****** ONE_MAIN_AND_THE_OTHERS ****/
/************************************/

.oneMainAndTheOthers__Container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.oneMainAndTheOthers__Container_Vertical {
  flex-direction: column;
}
.oneMainAndTheOthers__Container_VerticalReverse {
  flex-direction: column-reverse;
}
.oneMainAndTheOthers__Container_Horizontal {
  flex-direction: row;
}
.oneMainAndTheOthers__Container_HorizontalReverse {
  flex-direction: row-reverse;
}

.oneMainAndTheOthers__MainContainer {
  flex: 1;
  min-height: 0px;
}
.oneMainAndTheOthers__Container_Vertical .oneMainAndTheOthers__MainContainer {
  width: 100%;
}
.oneMainAndTheOthers__Container_VerticalReverse .oneMainAndTheOthers__MainContainer {
  width: 100%;
}
.oneMainAndTheOthers__Container_Horizontal .oneMainAndTheOthers__MainContainer {
  height: 100%;
}
.oneMainAndTheOthers__Container_HorizontalReverse .oneMainAndTheOthers__MainContainer {
  height: 100%;
}

.oneMainAndTheOthers__ControlsContainer {
  min-height: 0px;
  min-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  column-gap: 5px;
}
.oneMainAndTheOthers__Container_Vertical .oneMainAndTheOthers__ControlsContainer {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

}
.oneMainAndTheOthers__Container_VerticalReverse .oneMainAndTheOthers__ControlsContainer {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.oneMainAndTheOthers__Container_Horizontal .oneMainAndTheOthers__ControlsContainer {
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.oneMainAndTheOthers__Container_Horizontal .oneMainAndTheOthers__ControlsContainer * {
  writing-mode: horizontal-tb;
}
.oneMainAndTheOthers__Container_HorizontalReverse .oneMainAndTheOthers__ControlsContainer {
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.oneMainAndTheOthers__Container_HorizontalReverse .oneMainAndTheOthers__ControlsContainer * {
  writing-mode: horizontal-tb;
}

.oneMainAndTheOthers__Controls {
  width: 250px;
  height: 150px;
  min-height: 0px;
  min-width: 0px;
}