:root {
  --marge: 10px;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-purpule-heart);
  color: white;
  background-size: cover;
  background-position: center;
}

.loading {
  position: absolute;
  z-index: 10000;
}

.viewerContainer {
  color: var(--color-main-green);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  padding: 2px 8px;
}

.body {
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
}

.content {
  flex: 1;
  height: 100%;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.mainContent {
  height: 100%;
  width: 100%;
  padding: var(--marge);
  min-width: 0;
  min-height: 0;
  flex: 1;
}

.mainContentWithWhiteboard {
  min-width: 300px;
  max-width: 100%;
}

.hideContent {
  display: none;
}

.whiteboardContainer {
  padding: var(--marge);
  height: 100%;
  width: 100%;
  flex: 2;
  min-width: 0;
  min-height: 0;
}

.whiteboardContent {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(var(--color-main-purpule-rgb), 0.7);
  box-shadow: 0px 0px var(--marge) #00000088;  
  border-radius: 10px;
  overflow: hidden;
}

.players {
  width: 100%;
  height: 100%;
}

.sessionHeader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.popupPasswordContainer {
  min-width: 300px;
}

.popupPasswordTitle {
  font-size: 1rem;
  color: lightgrey;
  margin-bottom: 15px;
}

.popupPasswordDescription {
  margin-bottom: 5px;
}

.popupPasswordInput {
  width: 100%;
  height: 25px;
  border: unset;
  border-radius: 5px;
}

.sources {
}

.videoStats {
  position: absolute;
  top: 100px;
  left: 0;
}

.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerTab {
  --border-radius: 10px;
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  height: 55px;
}

.footerTabButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: 120px;
  height: 100%;
  font-size: 1rem;
  color: white;
  padding: 7px 0px;
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
}

.footerTabButtonSelected {
  background-color: var(--color-main-purpule);
}

.footerRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 15px;
}

.footerChatButtonContainer {
  border-radius: 10px;
  padding: 10px;
}

.joinMeetingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-height: 50%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(var(--color-blue-bayoux-rgb), 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
}

.joinMeetingContent {
  flex: 1;
  min-height: 0;
  width: 100%;
  padding: var(--marge);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.joinMeetingTitle {
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0px;
  margin-bottom: 30px;
}
.joinMeetingSubtitle {
  font-size: 1.5rem;
  font-weight: normal;
}

.joinMeetingDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  color: white;
  text-align: start;
  gap: 5px;
  margin-bottom: 2px;
}

.joinMeetingDetailsIcon {
  min-width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.joinMeetingButton {
  width: 100%;
  height: 40px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mountain-meadow);
  font-size: 1.2rem;
}

.chatContainer {
  --margin: 15px;
  position: absolute;
  z-index: 100;
  border-radius: 10px;
  right: 0px;
  padding: 10px;
  margin: var(--margin);
  height: calc(100% - 2*var(--margin));
  width: 500px;
  max-width: 80%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 0px 20px #000000EE;
  background-color: rgba(var(--color-blue-prussian-rgb), 0.3);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  /* blur effect */
}

.chatTitle {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.chat {
  width: 100%;
  flex: 1;
  min-height: 0px;
  --chat-message-background: #77777788;
  --chat-message-reactions-background: #777777;
  --chat-message-delete-button-background: #777777;

  --chat-message-background-for-me: rgba(var(--color-main-green-rgb), 0.8);
  --chat-message-reactions-background-for-me: var(--color-main-green);
  --chat-message-delete-button-background-for-me: var(--color-main-green);

  --chat-sendbar-background: #77777788;
  --chat-gobottom-button-color: #77777788;
  --chat-scrollbar-color: #77777788;
}


.avatar {
  width: unset;
  height: 30px;
  max-height: 100%;
  min-height: 0px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0px;
}

.viewerPanelContainer {
  padding: 10px;
  height: calc(100% - 26px);
  width: 300px;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #00000055;
  border-radius: 10px;
  margin-right: 20px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.viewerPanelHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 1.5rem;
}

.viewerPanelContent {
  flex: 1;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.viewerPanelRow {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.viewerPanelRowName {
  color: white;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userPopupContainer {
  width: 100%;
  height: 100%;
}

.userPopupName {
  font-size: 1.2rem;
  color: white;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userPopupAvatar {
  width: 500px;
  max-width: 50vh;
  border-radius: 0px;
}

.sessionFocusedContainer {
  position: fixed;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 11;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sessionFocusedBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: #333333AA;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

@media only screen and (max-width: 600px) {
  .content {
    display: block;
    overflow: auto;
  }  
}

/* Mobile */
@media only screen and (hover: none) and (pointer: coarse){
 :root {
  --marge: 3px;  
 }  
}