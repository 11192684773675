
.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  --volume-color-effect: var(--color-mountain-meadow); 
  --volume-size-effect: 8px; 
}

.volumeEffect {
  box-shadow: 0px 0px var(--volume-size-effect) var(--volume-color-effect), inset 0px 0px 2px var(--volume-color-effect);
}

.me {
  background-color: rgba(var(--color-main-purpule-rgb), 0.5) !important;
}