.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
}

.error {
  text-align: center;
}


.kastplayer .camera_controls-button-move {
  width: 35px !important;
  height: 35px !important;
}

.kastplayer .icon_selector-button-vertical,.icon_selector-button-horizontal img {
  width: 23px !important;
  height: 23px !important;
}

